export const formatMoney = value => {
  const formattedAmount = new Intl.NumberFormat("en-PH", {
    style: "currency",
    currency: "PHP",
  }).format(value);
  return formattedAmount;
};

export const modifiedFormatMoney = value => {
  const numericValue = parseFloat(value.replace(/,/g, ""));

  if (isNaN(numericValue)) {
    throw new Error("Invalid number format");
  }

  const formattedAmount = new Intl.NumberFormat("en-PH", {
    style: "currency",
    currency: "PHP",
  }).format(numericValue);

  return formattedAmount;
};
