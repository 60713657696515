import React, { useEffect, useState } from "react";
import { Alert, Col, Row, Spin } from "antd";
import { Link, useParams } from "react-router-dom";
import { css, styled } from "styled-components";

import StyledButton from "components/button/Button";
import History from "../components/History";
import { MobileContainer, StyledElement } from "../components/reusables";
import MainLayout from "layouts/MainLayout/MainLayout";
import CitizenProfile from "../components/CitizenProfile";
import { CitizenDetailHeader } from "../components/citizenDetailHeader";
import ProgramInfo from "./ProgramInfo";

import { useSocialServiceContext } from "../Context";
import { getCitizen } from "services/citizen";
import { ENTITIES } from "constant/entity";
import { desktop } from "utils/styles";

import { useQrScannerContext } from "pages/QRScan/Context";
import { getCanRedeemAkapMaipProgram, getIsAkapMaipUser, getIsAkapUser } from "utils/citizen";
import Eligible from "../components/Eligible";

const Container = styled(StyledElement)`
  background-image: none;
  padding-top: 0;
`;
const StyledCitizenDetailHeader = styled(CitizenDetailHeader)`
  ${desktop`
    display: none;

    > * {
      display: none;
    }
  `}
`;
const StyledMobileContainer = styled(MobileContainer)`
  position: relative;
  z-index: 1;
`;
const BackButton = styled(StyledButton)`
  height: 43px;
  border-radius: 11px;
  background: rgba(217, 217, 217, 0.21);
  min-width: 108px;
  span {
    color: initial;
  }
  @media only screen and (min-width: 768px) {
    background: white;
    span {
      color: initial;
    }
  }
`;
const RedeemButton = styled(StyledButton)`
  height: 43px;
  @media only screen and (min-width: 768px) {
    background: transparent !important;
  }

  ${({ theme }) => {
    const { entity, colors } = theme;
    let color = colors.primary;
    let hoverColor = colors.primaryHover;

    if (entity === ENTITIES.BPSF || entity === ENTITIES.AKAP_BIGAS || entity === ENTITIES.KAPMADUOLAN) {
      color = colors.secondary;
      hoverColor = colors.secondaryHover;
    }

    return css`
      background-color: ${color};
      border-color: ${color};

      &:hover,
      &:focus {
        background-color: ${hoverColor};
        border-color: ${hoverColor};
      }

      &:disabled {
        background-color: transparent !important;
        span {
          color: black;
        }
      }
    `;
  }};
`;

const CitizenDetail = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { setCitizen, citizen } = useSocialServiceContext();
  const { user: agencyUser } = useQrScannerContext();

  useEffect(() => {
    if (id) {
      getCitizen(id)
        .then(citizen => {
          setIsLoading(false);
          setCitizen(citizen);
        })
        .catch(error => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, []);

  if (!id) {
    throw Error("No ID provided");
  }

  const canRedeem = () => {
    const isAkapUser = getIsAkapUser(agencyUser)
    if (isAkapUser) {
      return getCanRedeemAkapMaipProgram(citizen, agencyUser);
    } else {
      // Any other agency (IDs that are not 1 or 2) can redeem
      return true;
    }
  };

  const isEligible = () => {
    return !getIsAkapMaipUser(agencyUser);
  };

  return (
    <Container>
      <StyledCitizenDetailHeader />
      <StyledMobileContainer>
        <MainLayout>
          {isLoading && <Spin />}
          {!citizen && !isLoading ? (
            <Row gap={15}>
              <Col span={24}>
                <Alert message={"Error"} showIcon type="error" description={"Having trouble loading citizen data"} />
              </Col>
              <Col span={24} justify={"center"}>
                <Link to={-1}>
                  <BackButton>Back</BackButton>
                </Link>
              </Col>
            </Row>
          ) : null}
          {citizen ? (
            <div>
              <CitizenProfile {...citizen} />

              {isEligible() ? (
                <Eligible />
              ) : (
                <>
                  <ProgramInfo />
                  <Row gutter={15} justify="center">
                    <Col>
                      <Link to={`/citizen/${id}/redeem`}>
                        <RedeemButton disabled={!canRedeem()}> Redeem Social Service</RedeemButton>
                      </Link>
                    </Col>
                    <Col>
                      <Link to={-1}>
                        <BackButton>Back</BackButton>
                      </Link>
                    </Col>
                  </Row>
                  {citizen ? <History /> : null}
                </>
              )}
            </div>
          ) : null}
        </MainLayout>
      </StyledMobileContainer>
    </Container>
  );
};

export default CitizenDetail;
