const { ROOT_URL } = require("config");

const refreshToken = async () => {
  try {
    // Logic to refresh the token
    // For example, using a refreshToken to get a new accessToken
    let formData = new FormData();
    formData.append("refresh", localStorage.getItem("refresh"));
    const refreshResponse = await fetch(ROOT_URL + "/auth/refresh/", {
      method: "POST",
      body: formData,
    });

    if (!refreshResponse.ok) {
      throw new Error("Could not refresh the token.");
    }

    const data = await refreshResponse.json();
    localStorage.setItem("access", data.access);
    return true;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false;
  }
};

async function fetchWithTokenRefresh(url, options = {}) {
  // Inject the Authorization header into all requests
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  // Perform the fetch call
  let response = await fetch(url, options);

  // If the response is unauthorized, try to refresh the token
  if (response.status === 401) {
    const didRefresh = await refreshToken();
    if (didRefresh) {
      // Update accessToken after refresh
      const newAccessToken = localStorage.getItem("access");
      options.headers.Authorization = `Bearer ${newAccessToken}`;

      // Retry the fetch call with the new token
      response = await fetch(url, options);
    } else {
      // Handle failure to refresh token, e.g., redirect to login
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      window.location.reload();
      console.error("Failed to refresh token.");
      // Redirect to login or perform other error handling
    }
  }

  return response;
}

// get paginated agency transactions
const getTransactionLogs = async (id, pageNumber) => {
  try {
    const url = `${ROOT_URL}/agency/user/${id}/transactions/?page=${pageNumber}`;

    const response = await fetchWithTokenRefresh(url, {
      method: "GET",
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

export { getTransactionLogs };
