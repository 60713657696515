import { PROGRAMS } from "constant/program"

export const getIsAkapMaipUser = (agencyUser) => [PROGRAMS.AKAP, PROGRAMS.MAIP].includes(agencyUser.overrides?.citizen_program?.program?.id)

export const getIsAkapUser = (agencyUser) => agencyUser.overrides?.citizen_program?.program?.id === PROGRAMS.AKAP;

export const getCanRedeemAkapMaipProgram = (citizen, agencyUser) => citizen?.codes?.some(code => [PROGRAMS.AKAP, PROGRAMS.MAIP].includes(code.program.id) && code.user_id === agencyUser.user.id) || false

export const formatPhoneNumber = (value) => {
  // format the input value as a Philippine phone number
  if (!value) return "";
  value = value.toString();
  if (value.startsWith("0")) return value.replace(/^0/, "63");
  if (value.startsWith("63")) return value;

  // setVal(`63${value}`);
  return `63${value}`;
}

export const parsePhoneNumber = (value) => {
  // parse the input value as a number
  if (!value) return 0;
  return value.toString().replace(/^(0|63)/, "");
}

export const isProgramVisible = (code, agencyId) => {
  if (agencyId === 2 && code?.program?.id !== PROGRAMS.AKAP) return false;
  if (agencyId === 1 && code?.program?.id !== PROGRAMS.MAIP) return false;
  return true;
};