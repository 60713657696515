import { useQrScannerContext } from "pages/QRScan/Context";
import React from "react";
import { getTransactionLogs } from "services/merchant.service";
import styled from "styled-components";
import { formatMoney, modifiedFormatMoney } from "utils/money";
import { Button, Spin } from "antd";
import { format, parseISO } from "date-fns";

const TransactionTable = styled.div`
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;

  h4 {
    font-weight: 600;
  }
`;

const Col = styled.div`
  width: 25%;
`;

const TableBody = styled.div`
  overflow: hidden;
  overflow-y: auto;
`;

const Recipient = styled.p`
  font-weight: bold;
  color: #1ba833;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;

  .number {
    font-weight: 500;
    letter-spacing: 1px;
  }
`;

const RecipientDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .date {
    color: #969696;
  }
`;

const RecipientExpense = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;

  p {
    margin-bottom: 0.5rem;
  }
`;

const RecipientSection = styled.div`
  border-top: 0.5px solid #979797;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LoadPageSection = styled(CenterDiv)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  button {
    border-radius: 11px;
    background-color: #ac141e;

    &:hover {
      background: #9a121b;
    }

    span {
      color: white;
    }
  }
`;

const TransactionLogs = () => {
  const [logs, setLogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const { user } = useQrScannerContext();

  const fetchTransactions = async pageNumber => {
    setLoading(true);

    const res = await getTransactionLogs(user?.id, pageNumber);

    const newItems = res?.results;

    if (newItems?.length) setLogs(prevLogs => [...prevLogs, ...newItems]);

    if (res?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    setLoading(false);
  };

  const loadNextPage = () => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const prevPageRef = React.useRef();

  React.useEffect(() => {
    if (user?.id) {
      if (prevPageRef.current !== page) {
        fetchTransactions(page);
        prevPageRef.current = page;
      }
    }
  }, [page, user]);

  return (
    <TransactionTable>
      <TableHeader>
        <Col>
          <h4>Weight</h4>
        </Col>
        <Col>
          <h4>Price / kg</h4>
        </Col>
        <Col>
          <h4>Gross Total</h4>
        </Col>
        <Col style={{ textAlign: "end" }}>
          <h4>Subs. Amount</h4>
        </Col>
      </TableHeader>

      <TableBody>
        {logs?.map(log => (
          <RecipientSection key={log.id}>
            <RecipientExpense>
              <Col>
                <p>{`${log?.overrides?.total_kilos || 0}kg`}</p>
              </Col>
              <Col>
                <p>{formatMoney(log?.overrides?.price_per_kilo || 0)}</p>
              </Col>
              <Col>
                <p>{modifiedFormatMoney(log?.overrides?.gross_total || 0)}</p>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <p>{modifiedFormatMoney(log?.overrides?.subsidized_amount || 0)}</p>
              </Col>
            </RecipientExpense>
            <RecipientDetails>
              <Recipient>
                {log?.citizen?.name}
                <div className="number">{log?.id}</div>
              </Recipient>
              <div className="date">{format(parseISO(log?.created_at), "MMM dd, yyyy h:mma")}</div>
            </RecipientDetails>
          </RecipientSection>
        ))}

        {hasMore && !loading && (
          <LoadPageSection>
            <Button onClick={() => loadNextPage()}>load more</Button>
          </LoadPageSection>
        )}

        {loading && (
          <CenterDiv>
            <Spin />
          </CenterDiv>
        )}
      </TableBody>
    </TransactionTable>
  );
};

export default TransactionLogs;
