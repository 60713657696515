import React, { useEffect, useState } from "react";
import { Alert, Col, Row, Spin } from "antd";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import StyledButton from "components/button/Button";
import { MobileContainer, StyledElement } from "../style";
import MainLayout from "layouts/MainLayout/MainLayout";
import { CitizenDetailHeader } from "../../SocialService/components/citizenDetailHeader";
import { useQrScannerContext } from "pages/QRScan/Context";
import Transaction from "../components/TransactionLogs";
import AngencyStats from "./AngencyStats";

import { desktop } from "utils/styles";
import arrowRight from "assets/arrow-right.svg";

const Container = styled(StyledElement)`
  background-image: none;
  padding-top: 0;
`;
const StyledCitizenDetailHeader = styled(CitizenDetailHeader)`
  ${desktop`
    display: none;

    > * {
      display: none;
    }
  `}
`;
const StyledMobileContainer = styled(MobileContainer)`
  position: relative;
  z-index: 1;
`;
const BackButton = styled(StyledButton)`
  height: 43px;
  border-radius: 11px;
  background: rgba(217, 217, 217, 0.21);
  min-width: 108px;
  span {
    color: initial;
  }
  @media only screen and (min-width: 768px) {
    background: white;
    span {
      color: initial;
    }
  }
`;
const TransactionSection = styled.div``;
const TransactionSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;

  img {
    margin-left: 0.5rem;
  }

  p {
    margin-bottom: 0;
  }

  h2 {
    font-weight: 600;
  }

  a {
    font-weight: bold;
    color: #1e50a3;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const MerchantDetails = styled.div`
  h2 {
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
  }
`;

const MerchantDetail = () => {
  const { user } = useQrScannerContext();

  return (
    <Container>
      <StyledCitizenDetailHeader />
      <StyledMobileContainer>
        <MainLayout>
          {!user ? (
            <Row gap={15}>
              <Col span={24}>
                <Alert message={"Error"} showIcon type="error" description={"Having trouble loading merchant data"} />
              </Col>
              <Col span={24} justify={"center"}>
                <Link to={-1}>
                  <BackButton>Back</BackButton>
                </Link>
              </Col>
            </Row>
          ) : null}
          {user ? (
            <>
              <MerchantDetails>
                <h2>{user?.name}</h2>

                <p>{user?.overrides?.address}</p>
              </MerchantDetails>

              <AngencyStats />

              {user ? (
                <TransactionSection>
                  <TransactionSectionHeader>
                    <h2 style={{ marginBottom: 0 }}>Transaction Logs</h2>

                    <Link to="/merchant/transactions">
                      View All
                      <img src={arrowRight} />
                    </Link>
                  </TransactionSectionHeader>

                  <Transaction />
                </TransactionSection>
              ) : (
                <CenterDiv>
                  <Spin />
                </CenterDiv>
              )}
            </>
          ) : null}
        </MainLayout>
      </StyledMobileContainer>
    </Container>
  );
};

export default MerchantDetail;
