import React from "react";
import styled from "styled-components";
import { Avatar, Col, Divider, Row } from "antd";
import { PROGRAMS } from "constant/program";
import { formatMoney } from "utils/money";
import { useQrScannerContext } from "pages/QRScan/Context";

const Container = styled.div``;

const CardContainer = styled.div`
  border-radius: 11px;
  position: relative;
  width: 100%;
  height: 60px;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : "")};
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
const Label = styled.div`
  font-size: 0.9em;
  line-height: 1;
  ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  flex: 0 0 100%;
`;
const Value = styled.div`
  flex: 0 0 100%;
  font-size: 1.5em;
  font-weight: 600;
  ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  span {
    ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  }
`;

const Card = ({ label, value, bgColor = null, textColor = null }) => {
  return (
    <CardContainer style={{ backgroundColor: bgColor || "inherit" }}>
      <Label style={{ color: textColor || "inherit" }}>{label}</Label>
      <div>
        <Value style={{ color: textColor || "inherit" }}>{value}</Value>
      </div>
    </CardContainer>
  );
};

const ProgramInfo = () => {
  const { user } = useQrScannerContext();

  const renderPrograms = () => {
    return (
      <Container>
        <Divider style={{ marginTop: "0" }} />
        <Row gutter={15}>
          <Col span={12}>
            <Card label="Status" value={user?.status} bgColor="#C8E0FF" textColor="#1D5094" />
          </Col>
          <Col span={12}>
            <Card
              label="Total Receivables"
              value={formatMoney(user?.finance?.total_receivables || 0)}
              bgColor="#E9EBFF"
              textColor="#293392"
            />
          </Col>
        </Row>
        {/* <Row gutter={15} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Card label="Total Received" value={formatMoney(user?.finance?.total_received || 0)} bgColor="#EBFFF8" textColor="#1A6E50" />
          </Col>
          <Col span={12}>
            <Card label="Remaining Balance" value={formatMoney(user?.finance?.remaining_balance || 0)} bgColor="#FFEDED" textColor="#E8383B" />
          </Col>
        </Row> */}
        <Divider style={{ marginBottom: "0" }} />
      </Container>
    );
  };

  return <>{renderPrograms()}</>;
};

export default ProgramInfo;
