import React, { lazy, Suspense } from "react";
import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("components/ampingKanunay/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("components/kaubanNato/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("components/serbisyongTinudanay/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_LAMI_KAAYO]: lazy(() =>
    import("components/serbisyongLamiKaayo/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.TINGOG_47]: lazy(() =>
    import("components/tingog47/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.GWAPONG_SERBISYO]: lazy(() =>
    import("components/gwapongSerbisyo/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.SERBISYONG_EXPRESS]: lazy(() =>
    import("components/serbisyongExpress/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.OYO_UY]: lazy(() =>
    import("components/oyoUy/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.MATIK_KANG_TINO]: lazy(() =>
    import("components/matikkangtino/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.ABTIK_MO_SERBISYO]: lazy(() =>
    import("components/abtikMoSerbisyo/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.MARICAR_ZAMORA]: lazy(() =>
    import("components/maricarZamora/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KANATO_INI]: lazy(() =>
    import("components/kanatoIni/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.IPATUPAD]: lazy(() =>
    import("components/ipatupad/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.TEAM_ALDU]: lazy(() =>
    import("components/teamAldu/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.ALMARIO]: lazy(() =>
    import("components/almario/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KAPMADUOLAN]: lazy(() =>
    import("components/kapMaDuolan/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KAYJAYRMATIK]: lazy(() =>
    import("components/kayJayrMatik/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.TATAKCENIZA]: lazy(() =>
    import("components/tatakCeniza/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KUYANGBAYAN]: lazy(() =>
    import("components/kuyaNgBayan/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.LEVELUP_SA_SERBISYO]: lazy(() =>
    import("components/levelupSaSerbisyo/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.SERBISYONG_MABATI_SA_TANAN]: lazy(() =>
    import("components/serbisyongMabatiSaTanan/header").then(module => ({
      default: module.Header,
    })),
  ),
};
const Component = mapping[entity];

export const TermsAndConditionsHeader = () => {
  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};
