import React, { Suspense, lazy } from "react";

import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("./AmpingKanunay").then(module => ({
      default: module.AmpingKanunay,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("components/kaubanNato/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("components/serbisyongTinudanay/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_LAMI_KAAYO]: lazy(() =>
    import("components/serbisyongLamiKaayo/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.TINGOG_47]: lazy(() =>
    import("components/tingog47/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.GWAPONG_SERBISYO]: lazy(() =>
    import("./GwapongSerbisyo").then(module => ({
      default: module.GwapongSerbisyo,
    })),
  ),
  [ENTITIES.SERBISYONG_EXPRESS]: lazy(() =>
    import("components/serbisyongExpress/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.OYO_UY]: lazy(() =>
    import("components/oyoUy/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.MATIK_KANG_TINO]: lazy(() =>
    import("components/matikkangtino/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.ABTIK_MO_SERBISYO]: lazy(() =>
    import("components/abtikMoSerbisyo/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.MARICAR_ZAMORA]: lazy(() =>
    import("components/maricarZamora/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.KANATO_INI]: lazy(() =>
    import("./KanatoIni").then(module => ({
      default: module.KanatoIni,
    })),
  ),
  [ENTITIES.IPATUPAD]: lazy(() =>
    import("components/ipatupad/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.TEAM_ALDU]: lazy(() =>
    import("./TeamAldu").then(module => ({
      default: module.TeamAldu,
    })),
  ),
  [ENTITIES.ALMARIO]: lazy(() =>
    import("./Almario").then(module => ({
      default: module.Almario,
    })),
  ),
  [ENTITIES.KAPMADUOLAN]: lazy(() =>
    import("./KapMaDuolan").then(module => ({
      default: module.KapMaDuolan,
    })),
  ),
  [ENTITIES.KAYJAYRMATIK]: lazy(() =>
    import("./KayJayrMatik").then(module => ({
      default: module.KayJayrMatik,
    })),
  ),
  [ENTITIES.TATAKCENIZA]: lazy(() =>
    import("./TatakCeniza").then(module => ({
      default: module.TatakCeniza,
    })),
  ),
  [ENTITIES.KUYANGBAYAN]: lazy(() =>
    import("./KuyaNgBayan").then(module => ({
      default: module.KuyaNgBayan,
    })),
  ),
  [ENTITIES.LEVELUP_SA_SERBISYO]: lazy(() =>
    import("./LevelupSaSerbisyo").then(module => ({
      default: module.LevelupSaSerbisyo,
    })),
  ),
  [ENTITIES.SERBISYONG_MABATI_SA_TANAN]: lazy(() =>
    import("components/serbisyongMabatiSaTanan/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
};
const Component = mapping[entity];

export const LoginFormContainer = ({ children }) => {
  return (
    <Suspense>
      <Component>{children}</Component>
    </Suspense>
  );
};
