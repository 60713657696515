import React from "react";
import styled from "styled-components";
import backArrow from "assets/back-arrow.svg";
import { Link } from "react-router-dom";
import TransactionLogs from "./components/TransactionLogs";

const Container = styled.div``;

const Header = styled.div`
  width: 100%;
  background: #2560fa;
  height: 64px;
  display: flex;
  flex-direction: row;
`;

const BackSection = styled.div`
  width: 15%;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const TitleSection = styled.div`
  height: 100%;
  width: 70%;
  align-items: center;
  display: flex;
  justify-content: center;

  h3 {
    color: white;
  }
`;

const LogsSection = styled.div`
  padding: 1rem;
`;

const Transaction = () => {
  return (
    <Container>
      <Header>
        <BackSection>
          <Link to={-1}>
            <img src={backArrow} />
          </Link>
        </BackSection>
        <TitleSection>
          <h3>Transaction Log</h3>
        </TitleSection>

        <BackSection style={{ opacity: 0 }}></BackSection>
      </Header>
      
      <LogsSection>
        <TransactionLogs />
      </LogsSection>
    </Container>
  );
};

export default Transaction;
