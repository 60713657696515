import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import GlobalStyle from "./GlobalStyles";
import { Spin } from "antd";
import NewCitizen from "./pages/Register/Form";
import "antd/dist/antd.min.css";
import RegisterHomepage from "./pages/Register/Homepage/RegisterHomePage";
import TermsAndConditions from "pages/Register/Form/steps/TermsAndCondition";
import { NewCitizenContext } from "pages/Register/Form/NewCitizenContext";
import QrScanPage from "pages/QRScan/QrScanPage";
import Error404Page from "pages/Error/404";
import CitizenDetail from "pages/SocialService/CitizenDetail/CitizenDetail";
import Redeem from "pages/SocialService/Redeem/Redeem";
import Search from "pages/Search";
import CitizenEdit from "pages/Edit/Form";
import StatisticPage from "pages/Statistics/StatisticPage";
import RequireAuth from "./auth/RequireAuth";
import Login from "pages/Login/Login";
import SummitIdGen from "pages/SummitIdGen";
import MerchantDetail from "pages/Merchant/MerchantDetail/MerchantDetail";
import Transaction from "pages/Merchant/Transaction";

function App() {
  const { agreement } = useContext(NewCitizenContext);

  return (
    <>
      <GlobalStyle />
      <React.Suspense fallback={<Spin />}>
        <Routes>
          <Route path="/" element={<RegisterHomepage />} />
          <Route exact path="/register" element={agreement ? <NewCitizen /> : <Navigate replace to={"/agreement"} />} />
          <Route exact path="/agreement" element={<TermsAndConditions />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<Error404Page />} />
          <Route exact path="/scan" element={<QrScanPage />} />
          <Route
            path="/search"
            element={
              <RequireAuth>
                <Search />
              </RequireAuth>
            }
          />
          <Route
            path="/statistics"
            element={
              <RequireAuth>
                <StatisticPage />
              </RequireAuth>
            }
          />
          <Route exact path="/citizen/:id" element={<CitizenDetail />} />
          <Route exact path="/citizen/:id/redeem" element={<Redeem />} />
          <Route exact path="/edit" element={<CitizenEdit />} />
          <Route exact path="/summit_gen" element={<SummitIdGen />} />
          <Route exact path="/merchant" element={<MerchantDetail />} />
          <Route exact path="/merchant/transactions" element={<Transaction />} />
        </Routes>
      </React.Suspense>
    </>
  );
}

export default App;
