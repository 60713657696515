import React, { lazy, Suspense } from "react";
import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("./AmpingKanunay").then(module => ({
      default: module.AmpingKanunay,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("./KaubanNato").then(module => ({
      default: module.KaubanNato,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("./SerbisyongTinudAnay").then(module => ({
      default: module.SerbisyongTinudAnay,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_LAMI_KAAYO]: lazy(() =>
    import("./SerbisyongLamiKaayo").then(module => ({
      default: module.SerbisyongLamiKaayo,
    })),
  ),
  [ENTITIES.TINGOG_47]: lazy(() =>
    import("./Tingog47").then(module => ({
      default: module.Tingog47,
    })),
  ),
  [ENTITIES.GWAPONG_SERBISYO]: lazy(() =>
    import("./GwapongSerbisyo").then(module => ({
      default: module.GwapongSerbisyo,
    })),
  ),
  [ENTITIES.SERBISYONG_EXPRESS]: lazy(() =>
    import("./SerbisyongExpress").then(module => ({
      default: module.SerbisyongExpress,
    })),
  ),
  [ENTITIES.OYO_UY]: lazy(() =>
    import("./OyoUy").then(module => ({
      default: module.OyoUy,
    })),
  ),
  [ENTITIES.MATIK_KANG_TINO]: lazy(() =>
    import("./MatikKangTino").then(module => ({
      default: module.MatikKangTino,
    })),
  ),
  [ENTITIES.ABTIK_MO_SERBISYO]: lazy(() =>
    import("./AbtikMoSerbisyo").then(module => ({
      default: module.AbtikMoSerbisyo,
    })),
  ),
  [ENTITIES.MARICAR_ZAMORA]: lazy(() =>
    import("./MaricarZamora").then(module => ({
      default: module.MaricarZamora,
    })),
  ),
  [ENTITIES.KANATO_INI]: lazy(() =>
    import("./KanatoIni").then(module => ({
      default: module.KanatoIni,
    })),
  ),
  [ENTITIES.IPATUPAD]: lazy(() =>
    import("./Ipatupad").then(module => ({
      default: module.Ipatupad,
    })),
  ),
  [ENTITIES.TEAM_ALDU]: lazy(() =>
    import("./TeamAldu").then(module => ({
      default: module.TeamAldu,
    })),
  ),
  [ENTITIES.ALMARIO]: lazy(() =>
    import("./Almario").then(module => ({
      default: module.Almario,
    })),
  ),
  [ENTITIES.KAPMADUOLAN]: lazy(() =>
    import("./KapMaDuolan").then(module => ({
      default: module.KapMaDuolan,
    })),
  ),
  [ENTITIES.KAYJAYRMATIK]: lazy(() =>
    import("./KayJayrMatik").then(module => ({
      default: module.KayJayrMatik,
    })),
  ),
  [ENTITIES.TATAKCENIZA]: lazy(() =>
    import("./TatakCeniza").then(module => ({
      default: module.TatakCeniza,
    })),
  ),
  [ENTITIES.KUYANGBAYAN]: lazy(() =>
    import("./KuyaNgBayan").then(module => ({
      default: module.KuyaNgBayan,
    })),
  ),
  [ENTITIES.LEVELUP_SA_SERBISYO]: lazy(() =>
    import("./LevelupSaSerbisyo").then(module => ({
      default: module.LevelupSaSerbisyo,
    })),
  ),
  [ENTITIES.SERBISYONG_MABATI_SA_TANAN]: lazy(() =>
    import("./SerbisyongMabatiSaTanan").then(module => ({
      default: module.SerbisyongMabatiSaTanan,
    })),
  ),
};
const Component = mapping[entity];

const RegisterHomePage = () => {
  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};

export default RegisterHomePage;
